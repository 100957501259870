import * as React from "react"
import Accordeons from "src/components/accordeons"
import Footer from "src/components/footer"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import TwoCols from "src/components/twoCols"
import footerData from "src/data/global/footer"
import { homeMeta, homeAbout, homeAtf, homeProjects, homeServices } from "src/data/pages/home/markup"

const IndexPage = () => (
  <Layout>
    <Seo title={homeMeta.title} description={homeMeta.description} url="/" />
    <TwoCols data={homeAtf} />
    <Accordeons data={homeServices} />
    <TwoCols data={homeAbout} />
    <TwoCols data={homeProjects} />
    <Footer data={footerData} hasBackground />
  </Layout>
)

export default IndexPage
