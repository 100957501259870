export const homeMeta = {
  title: "Web-Entwickler aus Innsbruck",
  description: "Du suchst einen professionellen und verlässlichen Web-Entwickler in Innsbruck? Dann bist du bei mir genau richtig! Ich unterstütze dich bei deinen Projekten!",
}

export const homeAtf = {
  imageLeft: true,
  imageCenter: false,
  imageColWidth: 6,
  isAtf: true,
  image: "transparent-3",
  alt: "Image of me, hugging a laptop",
  hasBackground: true,
  subHeading: "Hi, ich bin Dominik!",
  heading:
    "Ich bin <strong>Web-Entwickler</strong> mit Fokus auf <strong>React JS</strong>, <strong>Jamstack</strong> und <strong>Laravel</strong>.",
}

export const homeServices = {
  hasBackground: false,
  heading: "<strong>Was darf es Feines für dich sein?</strong>",
  text: "<p>Als Webentwickler und Lehrer konnte ich schon in zahlreichen Gebieten Erfahrungen sammeln. Ich bin mir sicher, dass auch du von meinem Fachwissen profiteren kannst.</p>",
  accordeons: [
    {
      title: "Website Komplettlösung",
      text: "Gemeinsam mit meinen PartnerInnen erstelle ich <strong>deine neue Website</strong>.</br></br> Ich übernehme die Planung und kümmere mich um DesignerIn, TexterIn und FotografIn. Nach deinem Feedback wird die Website auf Basis des Designs von mir entwickelt - mit passender Technologie je nach Wunsch und Budget.",
      readMore: "/services/website",
    },
    {
      title: "WordPress Development",
      text: "Über 50% der Websites weltweit werden mit WordPress erstellt und verwaltet. Dazu trage auch ich meinen Teil bei. Ich entwickle komplette WordPress-Webseiten, individuelle Themes oder sogar eigene Plugins, ganz nach deinen Wünschen und Anfroderungen.",
      readMore: "/services/wordpress",
    },
    {
      title: "Jamstack Website",
      text: "Ob der Jamstack wirklich <strong>'the next big thing'</strong> im Web sein wird? </br>Ich bin davon überzeugt! Was spricht denn auch gegen eine Website, die <strong>superschnell</strong> und <strong>sicher</strong> ist? Ich unterstütze dich bei der Konzeption, Planung und Entwicklung deiner Jamstack-Website.",
      readMore: "/services/jamstack",
    },
    {
      title: "Laravel Backend Entwicklung",
      text: "Manche Dinge sind noch immer komplizierter als sie sein müssten. <strong>Individuelle Backend-Lösungen</strong> können ihren Teil dazu beitragen, die Prozesse in deinem Unternehmen zu <strong>vereinfachen</strong> und zu <strong>automatisierten</strong>.",
      readMore: "/services/backend",
    },
    {
      title: "Freelance Web-Entwickler",
      text: "Du benötigst jemanden, der dir dich bei einem aktuellen Projekt unterstützt oder dir dabei hilft, Anfrage-Spitzen auszugleichen? Dabei kann ich dir weiter helfen.</br></br>Als erfahrener Freelancer kann ich Projekte komplett oder in Teilen übernehmen und so dein Team entlasten.",
      readMore: "/services/freelance",
    },
    {
      title: "Beratung & Support",
      text: "Als Digtal Native biete ich Beratungsgespräche für meine KundInnen zu unterschiedlichen Themen an. Sollte auf einer Website einmal etwas nicht wie gewünscht klappen, stehe ich gerne mit Rat und Tat zur Seite.",
      readMore: "/services/beratung",
    },
    {
      title: "Gleich Vorgespräch buchen - kostenlos & unverbindlich!",
      link: "https://calendly.com/dominik-schratl/besprechungen",
    },
  ],
}

export const homeAbout = {
  imageLeft: false,
  imageCenter: false,
  imageColWidth: 6,
  image: "transparent-1",
  alt: "Image of me, looking focussed",
  hasBackground: true,
  heading: "<strong>Hey there! Nice to meet you!</strong>",
  text: "<p>Ich heiße Dominik und wohne in Innsbruck. Ursprünglich komme ich aus Salzburg, genauer gesagt, aus dem wunderschönen Pinzgau.</p><p>Meine ersten Websites habe ich schon in meiner Schulzeit erstellt. Damals war ich 15 und Schüler im 'Computer-Zweig' an der HAK Zell am See. Mittlerweile unterrichte ich dort selber für einen Tag pro Woche.</p><p>Irgendwie hat mich die Web-Entwicklung nie losgelassen. Ich liebe es, neue Dinge zu entdecken, zu lernen und mich weiterzuentwickeln. Wahrscheinlich habe ich deshalb schon während des Studiums (Informatik & Geographie, Lehramt) begonnen, in Innsbrucker Agenturen zu arbeiten.</p><p>Mittlerweile bin ich seit September 2020 selbstständiger Web-Entwickler und Berater in Innsbruck.</p><p>Wenn du mehr über mich erfahren möchtest, findest du hier noch mehr Infos. Oder, wir unterhalten uns über ein gemeinsames Projekt? Ruf mich gerne an oder schreib mir eine Mail!</p>",
  button: {
    link: "/about",
    text: "Mehr über mich",
    isConfetti: true,
  },
}

export const homeProjects = {
  imageLeft: true,
  imageCenter: true,
  imagePadding: true,
  imageColWidth: 6,
  image: "home-projects",
  alt: "Screenshot of my Projects",
  hasBackground: false,
  heading: "<strong>Meine letzten Projekte waren … nice!</strong>",
  text: "<p>Hier findest du einen kleinen Überblick über meine letzten Projekte.<p>Als Freelancer kann ich aber leider nicht alles über meine Projekte erzählen. Deshalb habe ich die meine Erfahrungen als Case Studies in meinem Portfolio verarbeitet.</p><p>Dazwischen findest du noch den ein oder anderen Blogbeitrag.</p><p>Portfolio und Blog in einem?</p>",
  button: {
    link: "/blog",
    text: "Zum Blog-Folio :) ",
    isConfetti: false,
  },
}
